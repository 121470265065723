@pickColor: #050a22;

.tp-calendar-week-titles {
  border: none !important;
  margin: 0 !important;
  justify-content: start !important;
  width: 100%;
  box-sizing: border-box;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
    height: 40px;
    margin-bottom: 0px;
    margin-left: 8px !important;
    font-family: 'Averta CY 400';
  }
}
.tp-calendar-week {
  width: 100%;
  box-sizing: border-box;
  justify-content: start;

  .disabled {
    color: #75798d;
    font-family: 'Averta CY 400';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    &::after {
      display: none;
    }
  }
  .tp-calendar-day {
    width: 40px;
    height: 40px;
    font-family: 'Averta CY 400';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: @pickColor;
    margin: 4px;
  }
}

// cleadnr
.tp-calendar {
  font-size: 14px;
  font-family: 'Averta CY';
  box-sizing: border-box;

  .tp-calendar-day.range-select.start-date {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .tp-calendar-day.range-select.end-date {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .tp-calendar-day.range-select.same {
    border-top-left-radius: 100% !important;
    border-bottom-left-radius: 100% !important;
    border-top-right-radius: 100% !important;
    border-bottom-right-radius: 100% !important;
  }

  .tp-calendar-day.range-select {
    background-color: @pickColor;
    box-shadow: none;
  }

  .tp-calendar-day.range-select:hover {
    background-color: @pickColor;
  }

  .tp-calendar-day {
    color: @pickColor;
  }

  .tp-calendar-day:hover {
    background-color: #ccc;
  }
  .tp-calendar-month {
    margin-top: 22px;
  }
  .tp-calendar-month-select {
    border: none;
    width: 100%;
    box-sizing: border-box;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-family: 'Averta CY 400';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin: 4px;
    }
  }
  .tp-calendar-year-select {
    p:hover {
      background-color: @pickColor;
      color: white !important;
    }
  }
  .tp-calendar-month-select {
    .month-item-to-select {
      min-height: 25px;
    }
    height: auto;
  }

  .tp-calendar-month-select > div {
    width: 40px;
    height: 40px;
    margin: auto;
    background-color: @pickColor;
  }

  .tp-calendar-month-select > div:hover {
    background-color: @pickColor;
  }

  .tp-calendar-header {
    width: 100%;
    height: 20px;
    background-color: #fff;
    .action {
      height: 27px;
      width: 27px;
      svg {
        width: 7px;
        height: 7px;
        color: black;
      }
    }
    p {
      color: @pickColor;
      font-size: 16px;
      font-weight: 700;
      width: 150px;
      font-family: 'Averta CY 700';
      pointer-events: none;
    }
  }

  .tp-calendar-month {
    align-items: start;
  }

  .sc-gsnTZi {
    height: 100px;
    top: 20px;
  }
}

@media (min-width: 512px) {
  .tp-calendar-week {
    .tp-calendar-day {
      margin: 8px;
    }
  }

  .tp-calendar {
    left: -20px;
    .tp-calendar-week,
    .tp-calendar-week-titles {
      display: flex;
      justify-content: center !important;
      p {
        margin: 8px;
      }
    }
  }
}
