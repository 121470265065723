#root {
  height: 100vh;
}

.shadow {
  box-shadow: 0px 0px 2px rgb(224, 223, 223);
}
.mr2 {
  margin-right: 10px;
}

.text_ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ant-layout {
  background-color: #f6f6f6;
}

.mobile_hide {
  @media all and (max-width: 375px) {
    display: none;
  }
}

.pc_hide {
  @media all and (min-width: 375px) {
    display: none;
  }
}

@primary-color: #ba382c;