@font-face {
  font-family: 'Averta CY 800';
  src: url('./fonts/Averta-Cyrillic-Black.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Averta CY 600';
  src: url('./fonts/Averta-Cyrillic-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Averta CY 100';
  src: url('./fonts/Averta-Cyrillic-Extra-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Averta CY 700';
  src: url('./fonts/Averta-Cyrillic-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Averta CY 400';
  src: url('./fonts/Averta-Cyrillic-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Averta CY 500';
  src: url('./fonts/Averta-Cyrillic-SemiBold.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Averta CY 200';
  src: url('./fonts/Averta-Cyrillic-Thin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Averta CY';
  src: url('./fonts/avertaCY.woff2') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Averta CY';
  src: url('./fonts/avertaBold.woff2') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Averta CY';
  src: url('./fonts/avertaSemiBold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro';
  src: url('./fonts/minion.woff2') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Minion Pro';
  src: url('./fonts/minionSemibold.woff2') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Minion Pro';
  src: url('./fonts/minionBold.woff2') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@primary-color: #ba382c;