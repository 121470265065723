.creditScoreWrap {
  .MuiFormGroup-root {
    font-family: 'Averta CY';
    .MuiFormControlLabel-label {
      font-family: 'Averta CY';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      /* identical to box height, or 175% */

      text-align: center;

      /* Typography/Primary */

      color: #050a22;
    }
    .MuiFormControlLabel-labelPlacementEnd {
      width: 100%;
      height: 60px;
      border-width: 1px;
      border-style: solid;
      border-radius: 6px;
      margin-bottom: 12px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .MuiFormControlLabel-root {
    .MuiSvgIcon-fontSizeMedium {
      width: 18px;
      height: 18px;
      border-radius: 1px;
    }
    .MuiFormControlLabel-label {
      font-family: 'Averta CY';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      /* identical to box height, or 200% */
      color: #75798d;
    }
  }
}
