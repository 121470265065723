.schedule-tour-detail {
  .btn {
    border: 0.481px solid var(--Border, #e0e2ef);
  }
  .cancel-tour-info,
  .reschedule-tour-btn {
    font-family: 'Averta CY';
  }
}

.reason-list-dialog-box {
  font-family: 'Averta CY';
  .MuiPaper-root {
    margin: 0;
    border-radius: 12px;
    background: #fff;
    padding: 0 20px;
    width: 415px;
    .btn {
      border: 0.481px solid var(--Border, #e0e2ef);
    }

    .reason-text-area:focus {
      outline: none !important;
    }
  }
}

.mobile-box {
  .MuiPaper-root {
    align-self: flex-end;
    border-radius: 12px 12px 0px 0px;
    width: 100%;
  }
}
