.mui-form-lease-term-select {
  max-width: 230px;
  height: 32px;
  outline: none;
  width: 100%;

  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 0.4rem;
    min-height: 0px;
    padding-left: 12px;
    text-align: left;
    vertical-align: middle;
    position: relative;
    font-family: 'Averta CY';
    font-style: normal;
    font-weight: 600;
    &::after {
      position: absolute;
      right: 4px;
      top: 30%;
      display: block;
      content: '';
      width: 20px;
      height: 20px;
      background-image: url('https://tripalink-public.s3-accelerate.amazonaws.com/web/5d32bb2f9fe238b3.png');
      background-size: cover;
      transform: rotate(90deg);
    }
  }
  .MuiSelect-iconOutlined {
    display: none;
  }
}
.MuiMenu-list {
  .mui-form-lease-term-select-item {
    font-size: 0.4rem;
    height: 32px;
    font-family: 'Averta CY';
    font-style: normal;
    font-weight: 600;
  }
}
